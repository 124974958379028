(function(window, document, $) {
  'use strict';

  const baseURL = $('body').data('baseurl');

  const maskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  },
  options = {onKeyPress: function(val, e, field, options) {
      field.mask(maskBehavior.apply({}, arguments), options);
    }
  };

  function showMessageFeedback({ type, message }) {
    const html = `
      <span class="message-feedback__close"><i class="icon-close"></i></span>
      <div class="message-feedback__content">
        <p>${message}</p>
      </div>
    `;

    $('.message-feedback').html(html);

    setTimeout(function() {
      $('.message-feedback').addClass('is-active ' + type);
    }, 200);
  }

  function changeLoaderVisibility(visible) {
    if (visible) {
      $('body').addClass('show-loader');
    } else {
      $('body').removeClass('show-loader');
    }
  }

  function ajaxContentWrapper({ e = {}, url, method = 'GET', dataType = null, formData = null, callbackSuccess = (data) => { console.log(data)}, callbackError = (data) => { console.log(data) }, timeout = 0 }) {
    $.ajax({
      url,
      method,
      dataType,
      data: formData,
      beforeSend: function() {
        changeLoaderVisibility(true);
      },
      success: function(data) {
        callbackSuccess(data);
      },
      error: function(data) {
        callbackError(data);
      },
      complete: function() {
        changeLoaderVisibility(false);
      },
      always: function() {
        changeLoaderVisibility(false);
      }
    });
  }

  $(document).ready(() => {
    $('.phone').mask(maskBehavior, options);

    $('.menu-trigger').click(function() {
      $('.header').toggleClass('is-opened');

      if ($('.header').hasClass('is-opened')) {
        $('.menu-trigger span').html('fechar');
      } else {
        $('.menu-trigger span').html('menu');
      }
    });

    $('.js-scroll-to').click(function(e) {
      const target = $(this).attr('href');
      const offset = $(target).offset().top;

      if (target.includes('#')) {
        e.preventDefault();
        $('html, body').stop().animate({
          scrollTop: offset
        }, 2000);
      }
    });

    $('.message-feedback').on('click', function(e){
      const target = $(e.target);

      if(target.hasClass('is-active') || target.is('.icon-close') || target.is('.message-feedback__close')) {
        $('.message-feedback').removeClass('is-active success error');

        setTimeout(() => {
          $('.message-feedback').empty();
        }, 1500)
      }
    });

    $('.lp-join__form').submit(function(e) {
      e.preventDefault();
      const form = $(this);
      const url = form.attr('action');
      const formData = form.serialize();

      ajaxContentWrapper({
        url,
        formData,
        method: 'POST',
        callbackSuccess: (data) => {
          const response = JSON.parse(data);

          if (response.success) {
            $('#thankyou input').val(true);
            $('#thankyou').submit();
          } else {
            showMessageFeedback({
              type: response.success ? 'success' : 'error',
              message: response.message
            });
  
            setTimeout(function() {
              $('.message-feedback__close').trigger('click');
            }, 5000);
          }
        },
        callbackError: (error) => {
          console.error(error);
          showMessageFeedback({
            type: 'error',
            message: 'Erro ao enviar mensagem.'
          });
        }
      });
    });

  });

  $(window).scroll(() => {

  });

  $(window).resize(() => {

  });

})(window, document, jQuery);
